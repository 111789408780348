
import router from "@/router";
import { ChannelsModule } from "@/store/modules/channels";
import { ServersModule } from "@/store/modules/servers";
import { voiceChannelModule } from "@/store/modules/voiceChannels";
import { defineComponent, computed } from "vue";
import AvatarImage from "./AvatarImage.vue";
export default defineComponent({
  components: { AvatarImage },
  setup() {
    const channel = computed(() => {
      const channelId = voiceChannelModule.joinedChannelId as string;
      return ChannelsModule.channels[channelId];
    });
    const server = computed(() => {
      const serverId = channel.value.server_id as string;
      return ServersModule.servers[serverId];
    });

    const sharingScreen = computed(() => voiceChannelModule.videoStream);
    const streamingAudio = computed(() => voiceChannelModule.audioStream);

    const toggleMic = () => voiceChannelModule.toggleMic();
    const turnOffScreenshare = () => voiceChannelModule.removeVideoStream();
    const endCall = () => voiceChannelModule.leave();
    const goToChannel = () =>
      router.push(
        `/app/servers/${channel.value.server_id}/${channel.value.channelId}`
      );

    return {
      server,
      sharingScreen,
      streamingAudio,
      toggleMic,
      turnOffScreenshare,
      endCall,
      goToChannel,
    };
  },
});
